<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <h2 class="d-flex justify-content-center mb-10">Panduan Penggunaan Aplikasi Android</h2>

      <div class="row">
        <div class="col-lg-12">
          <embed 
            :src="layoutConfig('self.panduan.android')"
            width="100%" 
            height="900px" 
            type="application/pdf"
            >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { handleErrors } from "@/core/appUtil/util";
// import Swal from "sweetalert2";

// let token = ""

export default {
  name: "ManualWeb",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    // this.$store.dispatch("token/getAccessToken").then(access_token => {
    //   token = access_token;
    // }).then(() => {
    // });

  },
  methods: {
  }
};
</script>

<style scoped>
</style>
